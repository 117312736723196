import * as React from "react";
import Layout from "../../layout";
import { graphql, useStaticQuery } from "gatsby";
import get from "lodash/get";
import { RichText } from "prismic-reactjs";
import "./index.scss";

// markup
const PP = () => {
  const data = useStaticQuery(graphql`
    {
      allPrismicPrivacyPolicy {
        nodes {
          data {
            privacy_policy {
              richText
            }
          }
        }
      }
    }
  `);

  const tc = get(data, "allPrismicPrivacyPolicy.nodes", []);

  const ppText = tc[0].data.privacy_policy.richText;

  return (
    <Layout
      title={"Privacy Policy"}
      description={
        "The Most Trusted Real Time GNSS Positioning Service in Sri Lanka!"
      }
      publicPage
    >
      <section className="privacy-policy register py-8 py-lg-16">
        <article className="container row px-8 mx-auto">
          {RichText.render(ppText)}
        </article>
      </section>
    </Layout>
  );
};

export default PP;
